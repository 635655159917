// import external dependencies
import 'jquery';
import flatpickr from 'flatpickr';
// Import everything from autoload
import './autoload/_bootstrap.js'; import './autoload/jquery.matchHeight.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';
import yhteys from './routes/yhteys';
import contactUs from './routes/contact-us';
import singleProduct from './routes/product';
import cart from './routes/cart';
import ostoskori from './routes/cart';

//require('flatpickr');
//
flatpickr('#siivous_cal', {});
/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
  // yhteyssivu
  yhteys,
  contactUs,
  singleProduct,
  cart,
  ostoskori,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
