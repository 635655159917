// ?asia=asiakaspalvelu

export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // check url for ?asia=, then scroll to forminator -form, pre-select asia
    let urli = window.location.href;
    let muuttuja = '?asia=';
    if(urli.indexOf(muuttuja) !== -1) {

      let selected = urli.split('=');
      if(selected.indexOf('#')) {
        let selected_temp = selected[1].split('#');
        selected = selected_temp[0]
      } else {
        selected = selected[1];
      }

      // console.log('selected final on', selected);
      // pre-select forms select -field

      if( document.forms.length) {
        // console.log('form löytyy');
        let select = document.getElementById('forminator-form-120__field--select-1');
        for (let i = 0; i < select.length; i++) {
          let option = select.options[i];
          let value = option.value;
          value.toLowerCase();
          // poistetaan ääkköset?
          value = value.replace('ö','o');
          value = value.replace('ä','a');
          value = value.replace('å','o');
          // now have option.text, option.value
          // console.log('option value',option.value);
          if(value == selected) {
            // console.log('option value = selected ',option.value, selected);
            option.selected='selected';
          }
        }

        $('.forminator-guttenberg').show();

        let elem = document.querySelector('.forminator-guttenberg');
        let rect = elem.getBoundingClientRect();

        //console.log('elem ', elem );
        //console.log('rect ', rect);

        window.scrollTo({
          top: rect.top,
          left: 100,
          behavior: 'smooth',
        })
      }

    }

  },
};
