export default {
    init() {
      // JavaScript to be fired on the cart page
    },
    finalize() {
        $(document).ready(function () {

            /* $('body').on(
                'init_checkout payment_method_selected update_checkout updated_checkout checkout_error applied_coupon_in_checkout removed_coupon_in_checkout adding_to_cart added_to_cart removed_from_cart wc_cart_button_updated cart_page_refreshed cart_totals_refreshed wc_fragments_loaded init_add_payment_method wc_cart_emptied updated_wc_div updated_cart_totals country_to_state_changed updated_shipping_method applied_coupon removed_coupon reload_product_variations',
                function (e) {
                    console.log(e.type)
                }
            ) */

            function getCartContents()
            {
                console.log('getCartContents');
                fetch('//'+ window.location.hostname +'/wp-admin/admin-ajax.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: 'action=fetch_cart_items',
                })
                .then(response => response.json())
                .then(data => {
                  // Handle the retrieved cart items
                    console.log(data);
                  // Customize further processing based on your requirements
                    let tuotenimi = '';
                    let i = 0;

                    data.forEach(item => {
                      // const price = parseFloat(item.price);
                      // const quantity = parseInt(item.quantity);
                        const tuote = item.product_name;
                        const hintaryhma = item.hintaryhma;
                      // totalPrice += price * quantity;
                        tuotenimi = tuote;
                        sessionStorage.setItem('tuote_'+i, tuotenimi);
                        if (hintaryhma >= 1) {
                            sessionStorage.setItem('hintaryhma', hintaryhma);
                        }
                        i++;
                    });

                })
                .catch(error => {
                    console.error('Error:', error);
                  // Handle the error
                });
            }


            $('body').on('updated_cart_totals', function () {
                // console.log('updated_cart_totals 2');
                getCartContents();
            });

        });
    },
};
