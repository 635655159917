
export default {
    init() {
      // JavaScript to be fired on the product page
      // const flatpickr = require('flatpickr');
    },
    finalize() {

        const thisDay = new Date();
        const thisYear = thisDay.getFullYear();

      // JavaScript to be fired on the product page, after the init JS
        Date.prototype.addDays = function (days) {
            let date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        }
      //flatpickr('#siivous_cal', {});
        let today = new Date();
        let nextAvailableDate = today.addDays(3);
        let nextAvailableDate2 = today.addDays(1);
        //let nextAvailableDate3 = today.addDays(10);
        let maxDateAvailable = today.addDays(730);
        // let maxDateAvailable = '31.12.2025';


        // $('#siivous_cal').flatpickr({
        $('input[name="siivous_cal"]').flatpickr({
            dateFormat: 'd.m.Y',
            minDate: nextAvailableDate,
            maxDate: maxDateAvailable,
            'locale': {'firstDayOfWeek': 1 },
            disableMobile: true,
        });
      // ylläpitäjän kalenteri
      // $('#siivouksen_pvm').flatpickr({
      $('input[name="siivouksen_pvm"]').flatpickr({
            dateFormat: 'd.m.Y',
            minDate: today,
            'locale': {'firstDayOfWeek': 1 },
            disableMobile: true,
        });

      // lisäpalvelut
      $('input[name="toimituspvm"]').flatpickr({
            dateFormat: 'd.m.Y',
            minDate: nextAvailableDate2,
            maxDate: maxDateAvailable,
            'locale': {'firstDayOfWeek': 1 },
            disableMobile: true,
        });

      // liinavaatesetit
      if ( $('.admin-bar').length) {
        $('input[name="toimituspvm_liinavaate"]').flatpickr({
          dateFormat: 'd.m.Y',
          'locale': {'firstDayOfWeek': 1 },
          disableMobile: true,
        });
      } else {
        $('input[name="toimituspvm_liinavaate"]').flatpickr({
          dateFormat: 'd.m.Y',
          minDate: nextAvailableDate,
          maxDate: maxDateAvailable,
          'locale': {'firstDayOfWeek': 1 },
          disableMobile: true,
        });
      }

    function initCal(id, pvm)
    {
        if (id === 'siivous_cal') {
            $('input[name="siivous_cal]').flatpickr({
                dateFormat: 'd.m.Y',
                defaultDate: pvm,
                minDate: nextAvailableDate,
                maxDate: maxDateAvailable,
                'locale': {'firstDayOfWeek': 1 },
                disableMobile: true,
            });
        }
    }

    /*
    * poista käytöstä la ja su
    * 'disable': [
        function(date) {
          // return true to disable
          return (date.getDay() === 0 || date.getDay() === 6);
        },
      ],
    * */
    // let siivouspaiva_option = $('#siivouspaiva option:selected').val();
    // console.log('siivouspäivä 1',siivouspaiva_option);
    /*$('#siivouspaiva').change(function() {
      // siivouspaiva_option = $(this).val();
      console.log('siivouspäivä 2',siivouspaiva_option);
    }); */

    async function updatePrice(valittuPvm, selectId)
    {
        let valittuPvmArray = valittuPvm.split('.');
        valittuPvm = valittuPvmArray[2]+'/'+valittuPvmArray[1]+'/'+valittuPvmArray[0];
        let pvmDate = new Date(valittuPvm);
      // hae viikonpäivänumero
        let checkDay = pvmDate.getDay();

        let pyhaPvm = valittuPvmArray[2]+'-'+valittuPvmArray[1]+'-'+valittuPvmArray[0];
        let pyha = await checkHoliday(pyhaPvm);
        let arkipyha = await checkCommonHoliday(pyhaPvm);

        if (pyha !== undefined) {
            checkDay = 9;
        }
        if (arkipyha !== undefined) {
            checkDay = 8;
        }
        // console.log('checkDay',checkDay);
      // haetaan viikonpäivänumeron kanssa hinta
        let priceOption = getPriceOption(checkDay);
        // console.log('priceOption in updatePrice',priceOption);
        $(selectId).val(priceOption).attr('selected', 'selected').trigger('change');
    }

        // chatGPT edited version
    function getHolidays()
    {
        // Finnish Holidays, en.finnish#holiday@group.v.calendar.google.com
        const BASE_CALENDAR_URL = 'https://www.googleapis.com/calendar/v3/calendars';
        // Calendar Id. This is public but apparently not documented anywhere officially.
        const BASE_CALENDAR_ID_FOR_PUBLIC_HOLIDAY = 'holiday@group.v.calendar.google.com';
        const API_KEY = 'AIzaSyADWuVwhiDES-zYTnZ0Z3vf7gSkb1B5P4E';
        const CALENDAR_REGION = 'fi.finnish'; // This variable refers to the region whose holidays do we need to fetch

        /**
         * Making a fetch request
         */
        const url = '//' + window.location.hostname + '/app/plugins/kalenteri/files/pyhapaivat.json';
        const backup_url = `${BASE_CALENDAR_URL}/${CALENDAR_REGION}%23${BASE_CALENDAR_ID_FOR_PUBLIC_HOLIDAY}/events?key=${API_KEY}`;

        try {
            const palautus = fetch(url)
            .then((response, reject) => {
                if (response.ok) {
                    return response.json();
                } else {
                   // console.log('reject', reject);
                    return Promise.reject(reject);
                }
            })
            .then(data => {
                const holidays = data.pyha;
                const Dates = [];
              // suodata edellinen vuosi pois
                const suodatettu = holidays.filter(function (item) {
                    return item.start.date >= thisYear + '-00-00';
                });
              // yksinkertainen listaus pelkillä päivämäärillä
              suodatettu.forEach((element) => {
                    Dates.push(element.start.date);
                });
              return Dates;
            })
            .catch(error => {
                console.log('error 1', error);
              // If fetching from url fails, try the backup_url
                return fetch(backup_url)
                .then(response => response.json())
                .then(data => {
                    const holidays = data.items;
                    const Dates = [];
                    const suodatettu = holidays.filter(function (item) {
                        return item.start.date >= thisYear + '-00-00';
                    });
                  suodatettu.forEach((element) => {
                        Dates.push(element.start.date);
                    });
                  return Dates;
                })
                .catch(error => {
                    console.log('error 2', error);
                    return null; // If fetching from backup_url also fails, return null
                });
            });

            return palautus;
        } catch (error) {
            console.log('error 3', error);
            return null;
        }
    }

      // arkipyhät
    function getCommonHolidays()
    {
        // Finnish Holidays,en.finnish#holiday@group.v.calendar.google.com
        const BASE_CALENDAR_URL = '//'+ window.location.hostname +'/app/plugins/kalenteri/files/pyhapaivat.json';

        /**
         * Making a fetch request
         */
        try {
            const palautus = fetch(BASE_CALENDAR_URL).then(response => response.json()).then(data => {
                const holidays = data.arkipyha;
                const Dates = [];
              // suodata edellinen vuosi pois
                const suodatettu = holidays.filter(function (item) {
                    return item.start.date >= thisYear+'-00-00';
                })
            // yksinkertainen listaus pelkillä päivämäärillä
            suodatettu.forEach((element) => {
                  Dates.push(element.start.date);
                });
            return Dates;
            }).catch(error => {
                console.log('error 1 getCommonHolidays:', error);
                return false;
            });
            return palautus;
        } catch (error) {
            console.log('error 2 getCommonHolidays:', error);
            return false;
        }
    }
    // haetaan suomen arkipyhät
     // getHolidays();

    function checkHoliday(pyhaPvm)
    {
        if (pyhaPvm === '' ||  pyhaPvm === 'undefined') {
            return false;
        }
      // haetaan suomen arkipyhät
        const temp = getHolidays().then((pyhat) => {
            if (pyhat.length >= 1) {
                return pyhat.find(element => element === pyhaPvm);
            } else {
              // console.log('pyhat holiday empty');
                return false;
            }

        });

        return temp;
    }

    function checkCommonHoliday(pyhaPvm)
    {
        if (pyhaPvm === '' ||  pyhaPvm === 'undefined') {
            return false;
        }
        // haetaan suomen arkipyhät
        const temp = getCommonHolidays().then((pyhat) => {
            if (pyhat.length >= 1) {
                return pyhat.find(element => element === pyhaPvm);
            } else {
              //console.log('pyhat commonholiday empty');
                return false;
            }
        });
        return temp;
    }

    function getPriceOption(dayCode)
    {
        let priceOption = '';
        switch (dayCode) {
          // sunnuntai
            case 0:
                priceOption = 'Sunnuntai tai pyhä';
            break;
            case 6: // lauantai
                priceOption = 'Arki';
            break;
            case 8: // arkipyhä
                priceOption = 'Arkipyhä';
            break;
            case 9: // siivous pyhänä
                priceOption = 'Sunnuntai tai pyhä';
            break;
            default: // siivous arkena
                priceOption = 'Arki';
        }
      // console.log('priceOption getPrice:',priceOption);
        return priceOption;
    }
    /*
    $('body').on('found_variation', function () {
        console.log('found_variation triggered');
    });

      $('body').on('adding_to_cart', function () {
        console.log('adding_to_cart triggered');
      });

      $('body').on('wc_fragments_refreshed', function () {
        console.log('wc_fragments_refreshed triggered');
      });

      $('body').on('added_to_cart', function () {
        console.log('added_to_cart');
      });

      $('body').on('wc_cart_button_updated', function () {
        console.log('wc_cart_button_updated');
      });

      $('body').on('cart_page_refreshed', function () {
        console.log('cart_page_refreshed');
      });

      $('body').on('cart_totals_refreshed', function () {
        console.log('cart_totals_refreshed');
      });

      $('body').on('cart_page_refreshed', function () {
        console.log('cart_page_refreshed');
      });

      $('body').on('wc_fragments_loaded', function () {
        console.log('wc_fragments_loaded');
      });

      $('body').on('wc_fragments_refreshed', function () {
        console.log('wc_fragments_refreshed');
      });

      $('body').on('wc_fragments_loaded', function () {
        console.log('wc_fragments_loaded');
      });

      $('body').on('reload_product_variations', function () {
        console.log('reload_product_variations');
      }); */

      /*
     jQuery(document.body).on(
        'init_checkout payment_method_selected update_checkout updated_checkout checkout_error applied_coupon_in_checkout removed_coupon_in_checkout adding_to_cart added_to_cart removed_from_cart wc_cart_button_updated cart_page_refreshed cart_totals_refreshed wc_fragments_loaded init_add_payment_method wc_cart_emptied updated_wc_div updated_cart_totals country_to_state_changed updated_shipping_method applied_coupon removed_coupon reload_product_variations',
        function (e) {
          console.log(e.type)
        }
      ) */

      /**
       * Haetaan ostokorin sisältö ja laitetaan hintaryhmä -tieto sessionStorageen
       * */
    function getCartContents()
    {
       // console.log('getCartContents');
        fetch('//'+ window.location.hostname +'/wp-admin/admin-ajax.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: 'action=fetch_cart_items',
        })
         .then(response => response.json())
         .then(data => {
            // Handle the retrieved cart items
               // console.log(data);
            // Customize further processing based on your requirements
                let tuotenimi = '';
                let i = 0;

                data.forEach(item => {
                    // const price = parseFloat(item.price);
                    // const quantity = parseInt(item.quantity);
                    const tuote = item.product_name;
                    const hintaryhma = item.hintaryhma;
                    // totalPrice += price * quantity;
                    tuotenimi = tuote;
                    sessionStorage.setItem('tuote_'+i, tuotenimi);
                    if (hintaryhma >= 1) {
                        sessionStorage.setItem('hintaryhma', hintaryhma);
                    }
                    i++;
                });

                $('.woocommerce-notices-wrapper').html('<div class="woocommerce-message" role="alert">\n' +
                '\t\t<a href="//'+ window.location.hostname +'/ostoskori/" tabindex="1" class="button wc-forward">Näytä ostoskori</a> “'+ tuotenimi +'” on lisätty ostoskoriin.\t</div>');
            })
         .catch(error => {
                console.error('Error:', error);
            // Handle the error
            });
    }

      $('body').on('added_to_cart', function () {
        // console.log('added_to_cart 2');
        getCartContents();
      });

      /* $('body').on('adding_to_cart', function () {
        console.log('adding_to_cart triggered');
      }); */

      /*$('body.postid-1058').on('found_variation', function () {
        console.log('found_variation triggered');
        const hintaryhma = sessionStorage.getItem('hintaryhma');
        console.log('hintaryhma',hintaryhma);
        if (hintaryhma !== null) {
          // vaihdetaan variaatio hintaryhmän mukaiseksi.
            $('#hintaryhma').val(hintaryhma).attr('selected', 'selected').trigger('change');
        }
      }); */

    $('input[name="siivous_cal"]').on('change', function () {
        let valittuPvm = $(this).val();
        if (valittuPvm !== '') {
            updatePrice(valittuPvm,'#siivouspaiva');
            setSessionStorage('siivous_cal',valittuPvm);
        }
    });

    $('input[name="siivouksen_pvm"]').on('change', function () {
        let valittuPvm = $(this).val();
        if (valittuPvm !== '') {
            updatePrice(valittuPvm,'#siivouspaiva');
            setSessionStorage('siivous_pvm',valittuPvm);
        }
    });

    $('input[name="toimituspvm"]').on('change', function () {
        let valittuPvm = $(this).val();
        if (valittuPvm !== '') {
            updatePrice(valittuPvm,'#toimituspaiva');
            setSessionStorage('toimitusPvm',valittuPvm);
        }
    });

    /* asetetaan sessionstorageen valittupvm toimitukselle */
    function setSessionStorage(type, valittuPvm)
    {
        if (valittuPvm !== '') {
            sessionStorage.setItem(type, valittuPvm);
        }
    }

    if ($('input[name="toimituspvm"]').length) {
        let data = sessionStorage.getItem('toimitusPvm');
        if (data) {
            document.getElementById('toimituspvm').value = data;
            $('input[name="toimituspvm"]').flatpickr({dateFormat: 'd.m.Y',
                defaultDate: data,
                minDate: nextAvailableDate2,
                maxDate: maxDateAvailable,
                'locale': {'firstDayOfWeek': 1 },
                disableMobile: true,
            });
        }
    }


      /* $('.single_add_to_cart_button').on('click', function () {
         // let today = new Date();
         // let nextAvailableDate = today.addDays(3);
         //let pvm = $('#siivous_cal').val();
         //let pvmDate = new Date(pvm).toLocaleDateString();
         // let pvmCheck = new Date(pvmDate);
         let valittuPvm = $('#siivous_cal').val();
         console.log('valittu pvm on cart button click',valittuPvm);
         console.log('button add to cart today', today);
         console.log('button add to cart today.getTime', today.getTime());
         console.log('button add to cart nextA', nextAvailableDate);
         console.log('button add to cart pvm', pvm);
         console.log('pvmDate',pvmDate);
         console.log('pvmCheck',pvmCheck);
         console.log('pvmCheck gteTime',pvmCheck.getTime());
    }); */

      $(document).ready(function () {
        // jos on lisätty jo siivous ostoskoriin asiakkaana, päivitetään kalenteri uudestaan oikealla päivälle.
        if ($('input[name="siivous_cal"]').length) {
            let checkvalittuPvm = $('#siivous_cal').val();
            let get_siivous_cal_date = sessionStorage.getItem('siivous_cal');
            if (checkvalittuPvm !== get_siivous_cal_date) {
                initCal('siivous_cal',get_siivous_cal_date);
            }
        }
        if ($('body').hasClass('postid-1058')) {
          const hintaryhma = sessionStorage.getItem('hintaryhma');
          if (hintaryhma !== null) {
            // vaihdetaan variaatio hintaryhmän mukaiseksi.
            $('#hintaryhma').val(hintaryhma).attr('selected', 'selected').trigger('change');
          }
        }

      });
    },
};
