export default {
    init() {
      // JavaScript to be fired on all pages
    },
    finalize() {
      // JavaScript to be fired on all pages, after page specific JS is fired
        if ($('.nav').length) {
            $('.nav li.menu-item-has-children > a').append('<i class="fas fa-caret-right"></i>');
        }
        if ($('.banners .banner').length) {
            $(function () {
             //  $('.banners .banner .wrapper').matchHeight();
            });
        }
        if ($('.timeline').length) {
            $('.timeline h2').wrapInner('<span class="bb">');
            $('.timeline').prepend('<div class="sitruuna">')
        }

        if ($('.block.greenbg').length) {
            $(function () {
                $('.block.greenbg').matchHeight();
            });
        }
        if ($('.block.description').length) {
            $(function () {
                $('.block.description').matchHeight();
            });
        }
        if ($('.woocommerce ul.products li.product .woocommerce-loop-product__title').length) {
            $(function () {
                $('.woocommerce ul.products li.product .woocommerce-loop-product__title').matchHeight();
            });
        }
    },
};
